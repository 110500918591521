<template>
  <v-form class="pa-5" ref="form" v-model="valid" lazy-validation>
    <v-text-field
      v-model="form.securityPassword"
      label="Security Password"
      prepend-icon="mdi-lock"
      required
    ></v-text-field>
    <div class="mt-4">
      <v-btn
        depressed
        color="accent"
        block
        dark
        large
        @click="updateSecurityPassword"
      >
        Save Details
      </v-btn>
    </div>
  </v-form>
</template>

<script>
import { mapGetters, mapActions } from "vuex"
import api from "@/store/api"
import { mergeExistingEntriesIntoForm } from "@/helpers/utilities"
import { changesSaved, somethingWentWrong } from "@/helpers/utilities"

export default {
  data() {
    return {
      valid: true,
      form: {
        securityPassword: "",
      },
    }
  },
  computed: {
    ...mapGetters("contacts", ["selectedContact"]),
    ...mapGetters("company", ["companyId", "details"]),
    ...mapGetters("employee", ["userId"]),

    ...mapGetters("ui", ["formEditMode"]),
  },
  watch: {
    selectedContact() {
      this.bindSecurityPasswordformation()
    },
  },
  created() {
    this.bindSecurityPasswordformation()
  },
  methods: {
    ...mapActions("contacts", ["fetchContactsAfterUpdate"]),
    ...mapActions("notes", ["fetchNotes"]),


    bindSecurityPasswordformation() {
      if (this.formEditMode) {
        Object.entries(this.selectedContact).forEach(
          mergeExistingEntriesIntoForm(this.form)
        )
      }
    },
    async updateSecurityPassword() {
      try {
        const noteMessage = `Security Password updated for  ${this.selectedContact.title} ${this.selectedContact.firstName} ${this.selectedContact.lastName}`
        const passwordDetails = {
          dob: this.selectedContact.dob,
          contactId: this.selectedContact.contactId,
          password: this.form.securityPassword,
          noteMessage: noteMessage,
          companyId: this.details.companyId,
          userId: this.userId,
        }
        const response = await api.post("updatePassword", passwordDetails)
        if (response && response.status === 200) {
          this.passwordDetails = ""
          changesSaved(`Security Password updated for  ${this.selectedContact.title} ${this.selectedContact.firstName} ${this.selectedContact.lastName}`)
          this.fetchNotes()

          setTimeout(() => {
            this.fetchContactsAfterUpdate(this.companyId)
          }, 1000)
        }
      } catch (error) {
        somethingWentWrong()
      }
    },
  },
}
</script>
